
import {
  defineComponent,
  ref,
  onMounted,
  reactive,
  onUnmounted,
  onBeforeUnmount,
  inject,
} from "vue";
import store, { MutationNames } from "@/store/index";
import { loadMicroApp } from "qiankun";
import servers from "@/config/servers";
import config from "@/config/app.config";
import { useRoute, useRouter } from "vue-router";
import { eventCenter, event } from "@evideo/frontend-utils";
import { message as antdMessage } from "ant-design-vue";
import { setMicroAppMap } from "@/main";
import logger from "@evideo/logger";
import _ from "lodash";
import { SocketEventMap } from "@/utils/socket-event";
import { noticeCacheErrorDownloadResult } from "@/utils/socketCommon";

export default defineComponent({
  setup() {
    let isAndroid = ref(true);
    let pptLoading = ref(true);
    let fullScreen = ref(false);
    let pptFun = "";
    let pptLoadTxt = ref("努力加载PPT应用中...");
    let firstPptUrl = reactive({
      url: "",
    });
    const goBackUrl = ref("");
    const route = useRoute();
    const router = useRouter();
    const device = navigator.userAgent;
    isAndroid.value =
      device.indexOf("Android") > -1 || device.indexOf("Adr") > -1;
    const handleFirstLoadingPPT = () => {
      logger.info("准备加载的pptjson", firstPptUrl);
      setTimeout(() => {
        pptLoading.value = false;
      }, 0);
      if (store.state.minimizeBtnState !== "false") {
        logger.info("现在ppt", store.state.minimizePPTInfo);
        // 通知上级应用 可以加载ppt
        eventCenter.sub(`teachPptPC-load-ok`, () => {
          eventCenter.send(
            event.PPTIST_LOAD_PPT,
            store.state.minimizePPTInfo,
            (res) => {
              setTimeout(() => {
                store.commit(MutationNames.CHANGE_MINIMIZEBTNSTATE, "false");
              }, 500);
            }
          );
        });
      } else {
        // 通知上级应用 可以加载ppt
        eventCenter.sub(`teachPptPC-load-ok`, () => {
          _.merge(firstPptUrl, {
            url: firstPptUrl.url,
            runtime: {
              demMoreResourceBtn: false,
              state: {
                slideIndex: 0,
                screenType: "preview",
                screening: true,
              },
            },
          });
          logger.info("准备加载的pptjsone", firstPptUrl);

          eventCenter.send(event.PPTIST_LOAD_PPT, firstPptUrl, (res) => {
            logger.info("PPTIST_LOAD_PPT", res);
          });
        });
      }
    };
    const loadPPT = async () => {
      if (!window.qiankunStarted) {
        window.qiankunStarted = true;
      }
      const env = JSON.stringify(process.env.RELEASE_ENV);
      let entryUrl = config[servers.pptist];
      if (env === `"devp"` && localStorage.getItem("entryUrl") !== null) {
        entryUrl = localStorage.entryUrl;
      }
      logger.info("entryurl:", entryUrl);
      pptFun = await loadMicroApp(
        {
          name: "teachPptPC",
          entry: entryUrl,
          container: "#ppt-container2",
          props: {
            // preload: true ppt预加载的时候要传过去的参数
            args: {
              token: store.state.token,
              appName: "teachPptPC",
              runtime: {
                playPptRule: {
                  play: "demonstrate",
                  stop: "preview",
                },
              },
              os: store.state.hardwareModel,
              // preload: true
            },
            micrAppUrl: entryUrl,
            screening: true,
            screenType: "preview",
          },
          configuration: { $$cacheLifecycleByAppName: true }, // TODO: $$cacheLifecycleByAppName是qiankun的一个内部实验性开关
        },
        {
          sandbox: { experimentalStyleIsolation: true },
        }
      );
      await pptFun.mountPromise;
      try {
        setMicroAppMap("teachPptPC", pptFun);
      } catch (e) {
        console.error(e);
      }

      logger.info("ppt加载完成");
    };
    const goBack = _.throttle(async () => {
      // 用于安卓端，返回时卸载ppt
      logger.info("pptFun", pptFun);
      if (pptFun) {
        if (pptFun.preUnmount) {
          await pptFun.preUnmount();
        }
        await pptFun.unmount();
        logger.info("卸载成功");
      } else {
        logger.info("没有卸载直接返回");
      }
      if (goBackUrl.value) {
        router.push({ path: `/${goBackUrl.value}` });
      } else {
        history.back();
      }
    }, 3000);

    const socket = inject("socket");
    socket.on(
      SocketEventMap.STATIC_PROXY_CHECK_VERSION_ERROR,
      noticeCacheErrorDownloadResult
    );
    onMounted(async () => {
      firstPptUrl.url = route.query.pptUrl;
      goBackUrl.value = route.query.goBackUrl;
      pptLoading.value = true;
      setTimeout(() => {
        loadPPT();
        handleFirstLoadingPPT();
        // 接收子应用全屏命令
        eventCenter.sub(event.COMMON_FULL_SCREEN, (res) => {
          // res:获取事件a的参数
          if (res.fullScreen) {
            fullScreen.value = true;
          } else {
            fullScreen.value = false;
          }
        });
        // 接收子应用message
        eventCenter.sub(event.COMMON_NOTIFY_MESSAGE, (res) => {
          const { type, element } = res;
          antdMessage.config({
            top: `100px`,
            maxCount: 3,
            getContainer: () => element || document.body,
          });
          // const msg = res.message;
          const msg = (
            <span style="margin-left:10px; font-size:32px">{res.message}</span>
          );
          let msgFunc = () => {
            logger.info("msgFunc");
          };
          switch (type) {
            case "info":
              msgFunc = antdMessage.info;
              break;
            case "error":
              msgFunc = antdMessage.error;
              break;
            case "success":
              msgFunc = antdMessage.success;
              break;
            case "warning":
              msgFunc = antdMessage.warning;
              break;
            default:
              msgFunc = antdMessage.info;
          }
          msgFunc(msg, 3, () => {
            antdMessage.destroy();
          });
        });
      }, 0);
    });
    onBeforeUnmount(async () => {
      logger.info("unmounted");
      eventCenter.send("PPT_GET_STATUS", {}, (res) => {
        logger.info("现在课件广场pptu最小化", res);
        store.commit(MutationNames.CHANGE_MINIMIZEPPTINFO, res);
      });
      socket.off(
        SocketEventMap.STATIC_PROXY_CHECK_VERSION_ERROR,
        noticeCacheErrorDownloadResult
      );
      // setTimeout(async () => {
      // 用于pc端，goback返回时卸载ppt
      if (!isAndroid.value) {
        if (pptFun) {
          console.log("pptFun-->", pptFun);
          if (pptFun.preUnmount) {
            await pptFun.preUnmount();
          }
          pptFun.unmount();
          await pptFun.unmountPromise; // 等待卸载完成
          logger.info("卸载成功");
        } else {
          logger.info("没有卸载直接返回");
        }
      }
      // }, 100);
    });
    return {
      pptLoadTxt,
      pptLoading,
      isAndroid,
      fullScreen,
      goBack,
    };
  },
});
